import { useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { IoIosPie } from "react-icons/io";
import { IoCalendarSharp } from "react-icons/io5";
import { HiDocumentChartBar } from "react-icons/hi2";
import { GiProgression } from "react-icons/gi";
import { ImBooks } from "react-icons/im";
import { useAuth } from "@/context/AuthContext";
import { GoQuestion } from "react-icons/go";
import { useToast } from "./ui/use-toast";
import isCourseExpired from "@/utils/courseExpiry";
import { coursesList } from "@/data/global_config";
import GetUserRef from "@/utils/getUserRef";
import { getDoc } from "firebase/firestore";

const Sidebar = () => {
  const { toast } = useToast();
  const { isAdmin } = useAuth();
  const { pathname } = useLocation();
  const { docRef } = GetUserRef();

  const [notes, setNotes] = useState("");
  const [progress, setProgress] = useState("");

  const courseExpired = isCourseExpired();

  useEffect(() => {
    if (courseExpired) {
      toast({
        variant: "destructive",
        title: `Course Expired!`,
        description: "Please contact administrator",
      });
    }
  }, [courseExpired, toast]);

  useEffect(() => {
    const fetchUserDoc = async () => {
      try {
        if (docRef) {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setNotes(docSnap.data().MyNotes || "");
            setProgress(docSnap.data().MyProgress || "");
          }
        }
      } catch (error) {
        console.error("Error fetching user document:", error);
      }
    };

    fetchUserDoc();
  }, [docRef]);

  const courseCode = localStorage.getItem("courseCode");
  const plannerPage = coursesList[courseCode]?.plannerPage;
  const qnaPage = coursesList[courseCode]?.askQuestionsPage;
  const evaluationPage = coursesList[courseCode]?.evaluationPage;

  const links = [
    { title: "Home", icon: FaHome, link: "/" },
    ...(courseExpired
      ? []
      : [{ title: "Modules", icon: ImBooks, link: "/curriculum" }]),
    ...(plannerPage
      ? [{ title: "Planner", icon: IoCalendarSharp, link: "/planner" }]
      : []),
    ...(qnaPage ? [{ title: "Q&A", icon: GoQuestion, link: "/qna" }] : []),
    ...(evaluationPage
      ? [{ title: "Evaluation", icon: HiDocumentChartBar, link: "/evaluation" }]
      : []),
    ...(notes
      ? [{ title: "Notes", icon: HiDocumentChartBar, link: "/notes" }]
      : []),
    ...(progress
      ? [{ title: "Progress", icon: GiProgression, link: "/progress" }]
      : []),
    ...(isAdmin
      ? [{ title: "Reports", icon: IoIosPie, link: "/reports" }]
      : []),
  ];

  const isCurriculum = pathname.includes("curriculum");

  return (
    <div
      className={`bg-primary  ${
        isCurriculum ? "w-[4.6rem]" : "w-[275px]"
      }  md:w-[4.1rem] lg:w-[4.6rem] h-full md:max-h-[calc(100vh-6rem)] overflow-y-auto overflow-x-hidden`}
    >
      <div className="w-full mx-auto bg-transparent flex flex-col items-center justify-start gap-1">
        {links?.map((item, index) => {
          const Icon = item.icon;
          const isActive =
            item.title === "Home"
              ? pathname === item.link
              : pathname.includes(item.link);
          const isNewSection =
            item.title === "Q&A" || item.title === "Progress";

          return (
            <Link
              to={item?.link}
              className={`py-3 px-4 md:px-3 w-full ${
                isCurriculum ? "flex-col text-xs" : "flex-row text-base"
              } flex md:flex-col justify-start md:justify-center max-md:gap-x-2 items-center md:text-xs lg:scale-110 ${
                isActive ? "bg-[#e3ecfa] text-primary" : "bg-primary text-white"
              } ${isNewSection ? "border-b-[3px]" : ""}`}
              key={index}
            >
              <Icon />
              {item.title}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
